import React, {
  useMemo, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useModel } from '../generic/model-store';
import { SubsectionStatus } from '../course-home/outline/subsection-list/subsection/Subsection';
import InstructorToolbar from '../instructor-toolbar';
import OutlineHeader from '../course-home/outline/header/OutlineHeader';
import CourseTabs from '../course-home/outline/course-tabs/CourseTabs';
import { fetchWeightedScore } from '../course-home/data/thunks';

function CourseTabView({ intl, children }) {
  const {
    courseId,
    courseStatus,
    weightedScore,
  } = useSelector(state => state.courseHome);
  const dispatch = useDispatch();

  const tabLoaded = useMemo(() => courseStatus === 'loaded', [courseStatus]);

  const outline = useModel('outline', courseId);

  const {
    isSelfPaced,
    org,
    title,
    username,
    userTimezone,
    originalUserIsStaff,
    celebrations,
    canViewLegacyCourseware,
  } = useModel('courseHomeMeta', courseId);

  const { tabs } = useModel('courseHomeMeta', courseId);
  const innerTabs = useMemo(() => {
    if (!tabs) { return []; }
    const filteredTabs = tabs.filter(tab => tab.slug === 'progress' || tab.slug === 'outline');
    filteredTabs.splice(1, 0, {
      slug: 'schedule',
      title: 'Расписание',
      url: `${filteredTabs.find(tab => tab.slug === 'outline').url.slice(0, -4)}schedule`,
    });
    return filteredTabs;
  }, [tabs]);

  const lastLesson = useMemo(() => (
    tabLoaded
      ? { name: outline.resumeCourse.block, href: outline.resumeCourse.url }
      : {}
  ),
  [tabLoaded, outline.resumeCourse]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchWeightedScore(courseId));
    }
  }, [courseId]);

  const courseName = useMemo(() => (tabLoaded ? title : 'Loading...'), [tabLoaded, title]);
  const classMatesNumber = useMemo(() => (tabLoaded ? outline.studentsCount : 0), [tabLoaded, outline.studentsCount]);
  const courseProgress = useMemo(() => (weightedScore?.courseGrade ?? 0), [weightedScore]);
  const numberOfLessonsLeft = useMemo(() => (tabLoaded
    ? outline.courseBlocks.sequences?.[outline.resumeCourse.parentBlock]?.children?.filter(unitId => !outline.courseBlocks.units[unitId].complete).length
    : 0), [tabLoaded, outline.courseBlocks?.sequences, outline.resumeCourse?.parentBlock, outline.courseBlocks?.units]);

  return (
    <>
      <div>
        {originalUserIsStaff && (
        <InstructorToolbar
          courseId={courseId}
          canViewLegacyCourseware={canViewLegacyCourseware}
          tab="outline"
          variant="transparent"
          className="sf-outline-page__instructor-toolbar"
        />
        )}
        {/* {tabLoaded && ( */}
        <OutlineHeader
          courseName={courseName}
          lastLesson={lastLesson}
          classMatesNumber={classMatesNumber}
          courseProgress={courseProgress}
          numberOfLessonsLeft={numberOfLessonsLeft}
          isLoading={!tabLoaded}
        />
        {/* )} */}

      </div>
      <div className="sf-outline-page__outline-container">
        <CourseTabs tabs={innerTabs} />
        {children}
      </div>
    </>
  );
}

CourseTabView.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CourseTabView);
