import React, { useEffect, useState } from 'react';
import './ScheduleTab.scss';

import { useSelector } from 'react-redux';
import { Loader, Text, Title } from '@skillfactory/frontend-theme';
import { getScheduleData } from '../data/api';

const EMPTY_DATE_INFO_START = 'Не указана';
const EMPTY_DATE_INFO_DEADLINE = 'Не указан';

const ScheduleTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);

  const {
    courseId,
  } = useSelector(state => state.courseHome);

  useEffect(() => {
    if (!courseId) {
      return;
    }
    setIsLoading(true);
    getScheduleData(courseId)
      .then((data) => {
        setFetchedData(data.data);
        setIsLoading(false);
      })
      .catch(() => {
        window.location.href = `${window.location.href.slice(0, -8)}home`;
      });
  }, [courseId]);

  return (
    <div className="schedule-tab">
      <Title level={3}>Расписание</Title>
      {isLoading
        ? (
          <div className="schedule-tab__loader">
            <Loader />
          </div>
        )
        : fetchedData.map((chapterInfo) => (
          <div key={chapterInfo.chapter_id} className="schedule-tab__container">
            <Title level={6} style={{ marginBottom: 4 }}>{chapterInfo.display_name}</Title>
            <div className="schedule-tab__container-info">
              <Text level={2}>Название</Text>
              <Text level={2}>Дата выхода</Text>
              <Text level={2}>Дедлайн</Text>
            </div>
            {chapterInfo.children.map(sequentialInfo => (
              <div key={sequentialInfo.sequential_id} className="schedule-tab__container-info">
                <Text level={3} weight="regular">{sequentialInfo.display_name}</Text>
                <Text level={3} weight="regular">{(sequentialInfo.start
                  ? new Date(sequentialInfo.start).toLocaleString('ru-RU')
                  : EMPTY_DATE_INFO_START)}
                </Text>
                <Text level={3} weight="regular">{(sequentialInfo.due
                  ? new Date(sequentialInfo.due).toLocaleString('ru-RU')
                  : EMPTY_DATE_INFO_DEADLINE)}
                </Text>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default ScheduleTab;
